import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import '../assets/css/global.css';
import '../assets/scss/style.scss';
import Header from '../components/Universal/Header/Header';
import ScrollToTop from '../components/Universal/ScrollToTop';
import { BREAK_POINT_MOBILE } from '../assets/layout';
import { ViewportProvider } from '../hooks/useViewport';

const Wrapper = styled.div`
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  padding-top: 50px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    padding-top: 80px;
  }
`;

function MainLayout({ className, children }) {
  return (
    <Wrapper className={className} id="top">
      <ViewportProvider>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
        <ScrollToTop />
      </ViewportProvider>
    </Wrapper>
  );
}

MainLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  className: '',
  children: null,
};

export default MainLayout;
