const dataMode = (input) => {
  if (!input) return null;
  const outputData = [];
  if (input.length <= 0) return outputData;
  const keys = input.shift();
  input.shift();
  input.forEach((item) => {
    const tObj = {};
    keys.forEach((key, keyIndex) => {
      tObj[key] = item[keyIndex];
    });
    outputData.push(tObj);
  });
  return outputData;
};

const configMode = (input) => {
  if (!input) return null;
  const config = {};
  if (input.length <= 0) return config;
  input.forEach((item) => {
    const [k, v] = item;
    config[k] = v;
  });
  return config;
};

module.exports = {
  dataMode,
  configMode,
};
