import { useState, useCallback, useEffect } from 'react';
import { getGoogleSheetData } from '../utils/api';
import googleSheetConverter from '../utils/googleSheetConverter';

export default function useCandidateData(mode, sheetID, sheetIndex = 1) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const request = useCallback(
    async () => {
      try {
        setError(null);
        setLoading(true);
        let res = await getGoogleSheetData(sheetID, sheetIndex);
        res = googleSheetConverter[mode](res.data.values);
        setData(res);
        setLoading(false);
      } catch (e) {
        setError(`ERROR : ${JSON.stringify(e)}`);
        setLoading(false);
      }
    },
    [mode, sheetID, sheetIndex],
  );

  useEffect(() => {
    request();
  }, [request]);

  return [data, loading, error, request];
}
